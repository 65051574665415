import React from "react";
import Chatra from "@chatra/chatra";
import { ParallaxProvider } from "react-scroll-parallax";

// Parallax
export const wrapPageElement = ({ element, props }) => {
	return <ParallaxProvider>{element}</ParallaxProvider>;
};

// Chatra log pageViews for targeted chats and trigers
export const onRouteUpdate = () => {
	setTimeout(() => Chatra("pageView"), 1000);
};

// Page load on background when opening modal page directly
// Only works if in the modal contains a link to the page behind
export const onPrefetchPathname = ({ loadPage }) => {
	if (window.indexPageData === undefined) {
		loadPage("/carta").then((result) => {
			window.indexPageData = result;

			if (window.setIndexPageData) window.setIndexPageData();
		});
	}
};

const removeSmoothScroll = () => {
	document.documentElement.classList.remove("smoothScroll");
	setTimeout(() => {
		document.documentElement.classList.add("smoothScroll");
	}, 200);
};

// Para que al cerrar el modal vuelva a la posición de carta anterior
export const shouldUpdateScroll = ({
	routerProps: { location },
	prevRouterProps,
}) => {
	const keepScrollPosition = location.state?.keepScrollPosition;

	// If we're hitting a hash link in same page, ignore functionality
	if (
		location.hash &&
		prevRouterProps &&
		prevRouterProps.location.pathname === location.pathname
	) {
		// console.log(prevRouterProps);
		// console.log(location);
		// console.log("Same page hash navigation detected, escaping");
		return true;
	}

	removeSmoothScroll();

	if (keepScrollPosition) {
		// console.log(
		// 	`Navigating to previous scroll position of ${location.pathname}`
		// );
		return false;
	}

	// if (scrollToContent) {
	// 	return true;
	// }

	// console.log(`Navigating instantly to top of ${location.pathname}`);
	return [0, 0];
};
